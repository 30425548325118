@import './src/themes/variables';

.page-node {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;

  &__subtitle {
    grid-column: span 12;
    margin-bottom: 0.5rem;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    grid-column: 1/-1;
    margin-bottom: 3rem;
  }

  &__start-over-break {
    padding-bottom: 2rem;
  }

  &__results-break {
    margin: 2.25rem 0 2.5rem 0;
  }

  &__action--start-over {
    padding: 0.5rem;
  }

  &__action {
    grid-column: span 4;

    @media screen and (max-width: 768px) {
      grid-column: span 5;
    }

    @media screen and (max-width: 530px) {
      grid-column: span 12;
    }
  }

  &__dtc-results-button {
    margin-top: 3.875rem;
  }

  .video {
    grid-column: span 12;
  }

  &--columns {
    display: block;

    .page-node__content {
      @media screen and (min-width: $mq-lg) {
        display: block;
        column-count: 2;
      }
    }
  }

  &__pdf-viewer-dialog{
    height: 10000px;
    width: 1000px
  }
}
